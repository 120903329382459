import React, { useEffect, useState } from "react"
import LinkIcon from "../../../images/svgs/icons/link.svg"
import ChevronButton from "../../CoreUI/ChevronButton"

export interface Study {
  name: string
  source: string
  url: string
}

interface StudiesTileProps {
  studies: Array<Study | null>
}

const Studies: React.FC<StudiesTileProps> = ({ studies }) => {
  const studiesPerPage = 2
  const [page, setPage] = useState(0)

  // Reset page on new props
  useEffect(() => {
    setPage(0)
  }, [studies])

  const maxPages = Math.ceil(studies.length / studiesPerPage)

  const pageBack = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const pageForward = () => {
    if (page < maxPages - 1) {
      setPage(page + 1)
    }
  }

  return (
    <>
      {studies.length > 0 ? (
        <div>
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-black text-md font-bold">
              Studies of interest
            </h3>
            <div className="w-1 h-1 rounded-full bg-grey-200 mx-4" />
            <div className="py-1 flex items-center flex-1 justify-between">
              <span className="text-grey-400">
                {page * studiesPerPage + 1}
                {page * studiesPerPage + studiesPerPage > studies.length
                  ? ""
                  : ` - ${page * studiesPerPage + studiesPerPage}`}{" "}
                of {studies.length}
              </span>
              <div>
                <ChevronButton
                  onClick={pageBack}
                  disabled={page === 0}
                  direction="left"
                />
                <span className="w-2" />
                <ChevronButton
                  onClick={pageForward}
                  disabled={page === maxPages - 1}
                  direction="right"
                />
              </div>
            </div>
          </div>
          <section className="text-base">
            <ol>
              {studies.map((study, idx) => {
                if (
                  idx + 1 > page * studiesPerPage &&
                  idx + 1 <= page * studiesPerPage + studiesPerPage
                ) {
                  return (
                    <li
                      className="flex text-grey-650 mb-3"
                      key={`${study?.url}-${idx}`}
                    >
                      <label className="block text-sm leading-6">
                        [{idx + 1}]
                      </label>
                      <div className="inline-block ml-4 text-base">
                        <a
                          className="text-curiousblue hover:underline cursor-pointer block"
                          href={study?.url}
                          target="_blank"
                        >
                          <span>{study?.name}</span>
                          <span
                            className="inline ml-2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            &#65279;
                            <LinkIcon
                              className="inline-block"
                              style={{ width: 15, paddingBottom: "0.3rem" }}
                            />
                          </span>
                        </a>
                        <div className="text-sm">
                          <em>{study?.source}</em>
                        </div>
                      </div>
                    </li>
                  )
                }
                return null
              })}
            </ol>
          </section>
        </div>
      ) : null}{" "}
    </>
  )
}

export default Studies
