import React from "react"
import { connect } from "react-redux"
import NutrientIcon from "../../../images/svgs/icons/macronutrient.svg"
import { RootState } from "../../../state/createStore"
import generateQuotaObjects from "../../../utils/quotas/objectGeneration/generateQuotaObjects"

interface DataTableProps {
  nutrientNames: NutrientNames
  foodNutrientData: FoodsNutrientData["Food"]
  userQuotas: UserPersonalQuotas
  nutrientUnits: NutrientUnits
  quotaData: any
}

const DataTable: React.FC<DataTableProps> = ({
  nutrientNames,
  foodNutrientData,
  nutrientUnits,
  userQuotas,
}) => {
  const quotaObjects = generateQuotaObjects(foodNutrientData, userQuotas)
  return (
    <div
      className="flex-shrink-0 ml-6 bg-sushi-bg-light rounded-md border border-sushi-border text-sushi-text top-2 sticky overflow-y-scroll hide-scrollbar"
      style={{ top: 15, height: "75vh" }}
    >
      <div className="flex items-center mb-2 bg-sushi-bg-dark py-2 px-4 sticky top-0 border-b border-sushi-border">
        <NutrientIcon className="mr-2 fill-current text-sushi-header" />
        <h3 className="font-bold text-sushi-header text-smm">NUTRIENT DATA</h3>
        <span className="text-sushi-subtext flex-1 text-right">/100g</span>
      </div>
      <table className="mx-4">
        <tbody>
          {nutrientNames.map(nutrientName => (
            <tr
              className="text-sushi-text text-sm"
              key={`${nutrientName}-table-row`}
            >
              <td className="pr-3 border-r border-sushi-border">
                {nutrientName}
              </td>
              <td className="pl-3 pr-3 border-r border-sushi-border">
                {foodNutrientData[nutrientName]} {nutrientUnits[nutrientName]}
              </td>
              <td className="pl-2">
                <div
                  className="w-16 text-center rounded-md"
                  // style={{
                  //   backgroundColor: `rgb(${quotaObjects[nutrientName]?.color})`,
                  // }}
                >
                  {quotaObjects[nutrientName]?.displayValue
                    ? `${quotaObjects[nutrientName]?.displayValue} %`
                    : ``}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface DataTableOwnProps {
  foodID: string
}

const mapProps = (state: RootState, ownProps: DataTableOwnProps) => {
  const { foodsNutrientData, quotas } = state

  return {
    nutrientNames: foodsNutrientData.nutrientNames,
    foodNutrientData:
      foodsNutrientData.portionScaledFoodsNutrientData[ownProps.foodID],
    foodID: ownProps.foodID,
    userQuotas: quotas.userQuotas,
    nutrientUnits: foodsNutrientData.nutrientUnits,
    quotaData: quotas.quotasData,
  }
}

export default connect(mapProps)(DataTable)
