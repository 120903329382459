const interpColorFromPercent = (color1: RGB, color2: RGB, weight: number) => {
  var w1 = weight / 100
  //prevent over 2x saturation
  w1 = w1 > 1.2 ? 1.2 : w1
  var w2 = 1 - w1
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ]
  return rgb
}
export default interpColorFromPercent
