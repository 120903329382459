import createQuotaObject from "./createQuotaObject"

//** QuotaObject: A defined  */

const generateQuotaObjects = (
  foodNutrientData: FoodsNutrientData["food"],
  userPersonalQuotas: UserPersonalQuotas
): QuotaObjects => {
  const nutrientNames = Object.keys(foodNutrientData)

  let quotaObjects = {}
  for (let nutrient of nutrientNames) {
    if (userPersonalQuotas[nutrient] !== null) {
      quotaObjects[nutrient] = createQuotaObject(
        foodNutrientData[nutrient],
        userPersonalQuotas[nutrient],
        nutrient
      )
    } else {
      quotaObjects[nutrient] === null
    }
  }

  return quotaObjects
}
export default generateQuotaObjects
