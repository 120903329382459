import generatePercent from "../generatePercent"
import interpColorFromPercent from "../interpColorFromPercent"
import { quotas as quotaData } from "../../../data/quotasData.json"

const lightgrey: RGB = [245, 245, 245]
const lightgreen: RGB = [161, 220, 66] //[144, 238, 144];
const red: RGB = [210, 70, 100]

const defaultColorMap = {
  min: lightgrey,
  max: lightgreen,
  warningMin: lightgrey,
  warningMax: red,
}

interface ColorMap {
  min: RGB
  max: RGB
  warningMin: RGB
  warningMax: RGB
}

interface ParseMicroQuotaArgs {
  foodNutrientQuantity: number
  personalQuotas: RDAULQuota
  nutrientName: string
  colorMap?: ColorMap
}

const parseRDAULQuota = ({
  foodNutrientQuantity,
  personalQuotas,
  nutrientName,
  colorMap = defaultColorMap,
}: ParseMicroQuotaArgs): QuotaObject | null => {
  //Use AL/RDA/UL to get quotaObject data
  //Start with just rda to get working
  let rdaPercent: number = 0
  let ulPercent: number = 0
  let quotaObject: QuotaObject = {
    type: "RDA_UL",
    warning: false,
    color: "none",
    displayValue: 0,
    tooltipText: `test`,
    typeValues: {
      rdaPercent: null,
      rdaDisplayColor: null,
      ulPercent: null,
      ulDisplayColor: null,
    } as RDAULQuotaTypeValues,
  }

  if (personalQuotas.rda) {
    rdaPercent = generatePercent(foodNutrientQuantity, personalQuotas.rda)
    quotaObject.typeValues.rdaPercent = rdaPercent
    if (personalQuotas.ul) {
      ulPercent = generatePercent(foodNutrientQuantity, personalQuotas.ul)
      quotaObject.typeValues.ulPercent = ulPercent
    }
    const ulColor = interpColorFromPercent(
      colorMap.warningMax,
      colorMap.warningMin,
      ulPercent
    ).toString()
    quotaObject.typeValues.ulDisplayColor = ulColor
    const rdaColor = interpColorFromPercent(
      colorMap.max,
      colorMap.min,
      rdaPercent
    ).toString()
    quotaObject.color = rdaColor
    quotaObject.typeValues.rdaDisplayColor = rdaColor
    quotaObject.displayValue = rdaPercent
    if (ulPercent > 100) {
      quotaObject.warning = true
      quotaObject.color = colorMap.warningMax.toString()
      quotaObject.displayValue = ulPercent
      quotaObject.tooltipText = `Exceeds limit by ${parseFloat(
        Math.abs(personalQuotas.ul - foodNutrientQuantity).toPrecision(2)
      )}${quotaData[nutrientName].units}`
    }
    return quotaObject
  }
  return null
}
export default parseRDAULQuota
