import React from "react"
import FoodTypePill from "../../CoreUI/FoodTypePill"

interface FoodTitleProps {
  name: string
  type: string
}

const FoodTitle: React.FC<FoodTitleProps> = ({ name, type }) => {
  return (
    <div className="flex relative items-center py-4 mt-2 justify-between sticky top-0 bg-white">
      <div className="flex items-center">
        <h2 className="text-2xl text-black mr-6 font-bold">{name}</h2>
        <FoodTypePill foodType={type} />
      </div>
      <div>
      </div>
    </div>
  )
}
export default FoodTitle
