import { graphql } from "gatsby"
import React from "react"
import { Query } from "../../graphql-types"
import "../components/Encyclopedia/Foods/encyclopedia.css"
import Description from "../components/Encyclopedia/Foods/Description"
import FoodTitle from "../components/Encyclopedia/Foods/FoodTitle"
import ProductionMap from "../components/Encyclopedia/Foods/ProductionMap"
import Studies, { Study } from "../components/Encyclopedia/Foods/Studies"
import "../css/scrollbar.css"
import SEO from "../components/seo"
import DataTable from "../components/Encyclopedia/Foods/DataTable"
import { Media } from "../media"

const removeHTMLTags = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, "")
}

interface EncyclopediaPageProps {
  data: Query
  location: Location
}

const EncyclopediaPage: React.FC<EncyclopediaPageProps> = props => {
  const context = props.context

  return (
    <>
      <SEO
        title={`${context?.name} - Food Encyclopedia`}
        description={removeHTMLTags(context?.description)}
      />
      <FoodTitle name={context?.name || ""} type={context?.type || ""} />
      <div className="flex pb-16">
        <div className="max-w-2xl">
          <Description description={context?.description || ""} />
          <div className="h-4" />
          <Studies studies={(context?.studies as Array<Study>) || []} />
          <div className="h-4" />
          <ProductionMap foodId={context?.foodId} />
          <div className="h-4" />
        </div>
        <Media greaterThanOrEqual="xl">
          <DataTable foodID={context.name} />
        </Media>
      </div>
    </>
  )
}
export default EncyclopediaPage

export const pageQuery = graphql`
  query EncyclopediaQuery($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            name
            type
            description
            studies {
              name
              source
              authors
              url
            }
            foodId
          }
        }
      }
    }
  }
`
