import React from "react"

interface DescriptionTileProps {
  description: string
}

const Description: React.FC<DescriptionTileProps> = ({ description }) => {
  return (
    <section
      className="text-base encyclopedia-description text-grey-650"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  )
}
export default Description
