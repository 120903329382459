import React from "react"
import foodTypeColorMap from "../../data/foodTypeColorMap"

interface ColorMap {
  [key: string]: {
    text: string
    bg: string
  }
}

const colorMap: ColorMap = foodTypeColorMap

interface FoodTypePillProps {
  foodType: string | null
}

const FoodTypePill: React.FC<FoodTypePillProps> = ({ foodType }) => {
  return (
    <>
      {foodType !== null ? (
        <div
          className="flex items-center rounded-full px-4 py-1 bg-grey-100 text-grey-400 inline text-smm font-bold"
          style={{
            color: colorMap[foodType].text,
            backgroundColor: colorMap[foodType].bg,
          }}
        >
          {foodType.toUpperCase()}
        </div>
      ) : null}
    </>
  )
}
export default FoodTypePill
