import generatePercent from "../generatePercent"
import parseRDAULQuota from "./parseRDAULQuota"

const createQuotaObject = (
  foodNutrientQuantity: FoodsNutrientData["food"]["nutrient"],
  personalQuotas: UserPersonalQuotas["nutrient"],
  nutrientName: string
): QuotaObject | null => {
  switch (personalQuotas.type) {
    case "unique": {
      if (nutrientName === "Energy") {
        const { eer } = personalQuotas as EnergyQuota
        return {
          type: "RDA_UL",
          warning: false,
          color: "green",
          displayValue: generatePercent(
            foodNutrientQuantity,
            Number(eer) //Estimated energy requirement
          ),
          tooltipText: `Test`,
          typeValues: {},
        }
      } else if (nutrientName === "water") {
        return null
      }
    }
    case "RDA_UL": {
      return parseRDAULQuota({
        foodNutrientQuantity,
        personalQuotas,
        nutrientName,
      })
    }
    default:
      return null
  }
}
export default createQuotaObject
