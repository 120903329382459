import * as d3 from "d3"
import React, { useEffect, useRef, useState } from "react"
import * as topojson from "topojson"
import countriesJson from "../../../data/countries-50m.json"
import { metadata } from "../../../data/foodsMetadata.json"
import LinkIcon from "../../../images/svgs/icons/link.svg"

const COLOR_EMPTY = "#f3f5ed"
const COLOR_LOW = "#daefb9"
const COLOR_HIGH = "#86bf2b"

const getFoodMetadata = (foodId: number) => {
  const objIndex = metadata.findIndex(metadataObj => {
    return metadataObj.id === foodId
  })
  const foodObject = metadata[objIndex]
  return foodObject
}

function convertLabel(labelValue: number) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? Number(Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue))
}

interface ProductionMapProps {
  foodId: string
}

const ProductionMap: React.FC<ProductionMapProps> = ({ foodId }) => {
  const ref = useRef(null)
  const [loading, setLoading] = useState(true)

  const foodObject = getFoodMetadata(Number(foodId))
  let productionData: Array<[string, number]> = []

  if (foodObject?.productionDataAvailable) {
    productionData = require(`../../../data/productionData/${foodId}.json`)[
      "data"
    ]
  }

  useEffect(() => {
    let debounceTimer: null | NodeJS.Timeout = null

    const debounceResizeEvent = () => {
      if (debounceTimer !== null) {
        clearTimeout(debounceTimer)
      }
      debounceTimer = setTimeout(drawMap, 1000)
    }

    const drawMap = () => {
      if (ref.current) {
        var svg = d3.select(ref.current)

        // Clear contents of svg
        svg.selectAll("*").remove()

        // Find height/width - width dynamic
        const refWidth = 650
        var width = svg.node()?.parentNode.clientWidth
          ? Math.min(svg.node()?.parentNode.clientWidth, 600)
          : refWidth
        var height = +svg.attr("height")

        // Map and projection
        var projection = d3
          .geoMercator()
          .scale((width / refWidth) * 100)
          .center([0, 20])
          .translate([width / 2, height / 2])
        var path = d3.geoPath(projection)

        // const map = productionData.map(val => [val.id, val.value])
        // Data and color scale
        var data = new Map(productionData)

        let world = JSON.parse(JSON.stringify(countriesJson))
        let countries = topojson.feature(world, world.objects.countries)

        let color = d3
          .scaleSequential()
          .domain(d3.extent(Array.from(data.values())))
          .interpolator(d3.interpolateLab(COLOR_LOW, COLOR_HIGH))
          .unknown(COLOR_EMPTY)
        // Load external data and boot

        const defs = svg.append("defs")

        defs.append("path").attr("id", "outline").attr("d", path(outline))

        // defs
        //   .append("clipPath")
        //   .attr("id", "clip")
        //   .append("use")
        //   .attr("xlink:href", new URL("#outline", location))

        const g = svg
          .append("g")
          .attr("clip-path", `url(${new URL("#clip", location)})`)

        g.append("use").attr("fill", "white")

        g.append("g")
          .selectAll("path")
          .data(countries.features)
          .join("path")
          .attr("class", "country")
          .attr("fill", d => {
            return color(data.get(d.id))
          }) // d.id is the ISO numeric country value
          .attr("d", path)
        // .append("title")
        //       .text(
        //         d => `${d.properties.name}
        // ${data.has(d.properties.name) ? data.get(d.properties.name) : "N/A"}`
        //       )

        g.append("path")
          .datum(
            topojson.mesh(world, world.objects.countries, (a, b) => a !== b)
          )
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-linejoin", "round")
          .attr("d", path)

        svg
          .append("use")
          // .attr("xlink:href", new URL("#outline", location))
          .attr("fill", "none")
          .attr("stroke", "black")

        const tooltip = svg.append("g")

        const format = d => `${convertLabel(d)} tonnes / year`

        svg
          .selectAll(".country")
          .on("touchmove mousemove", function (event, d) {
            if (data.get(d.id)) {
              tooltip.call(
                callout,
                `${format(data.get(d.id))} \n${d.properties.name}`
              )
              tooltip.attr("transform", `translate(${d3.pointer(event, this)})`)
            }
            // d3.select(this).attr("stroke", "red").raise()
          })
          .on("touchend mouseleave", function (event, d) {
            if (data.get(d.id)) {
              tooltip.call(callout, null)
              d3.select(this).attr("stroke", null).lower()
            }
          })

        //Efficiently update the svg without ever rerendering
        // svg.selectAll(".country").attr("fill", d => {
        //   return color(data.get(d.id))
        // }) // d.id is the ISO numeric country value)
      }
      setLoading(false)
    }

    drawMap()

    window.addEventListener("resize", debounceResizeEvent)
    return function cleanup() {
      window.removeEventListener("resize", debounceResizeEvent)
    }
  }, [])

  return (
    <>
      <div className="flex justify-between md:items-end items-start mb-2 flex-col md:flex-row">
        <h3 className="text-black text-md font-bold">Global Production Map</h3>
        {productionData.length > 0 ? (
          <label className="text-xsm m-0 p-0 text-grey-400 flex">
            Source:{"  "}
            <a
              href="http://www.fao.org/faostat/en/#home"
              className="text-xsm m-0 p-0 text-curiousblue hover:underline ml-1"
            >
              <em>FAO</em> - 2018 Production Data
              <LinkIcon
                className="inline"
                style={{ height: 13, marginBottom: "0.2rem" }}
              />
            </a>
          </label>
        ) : null}
      </div>
      {productionData.length > 0 ? (
        <div className="border border-grey-150 rounded-md overflow-hidden relative w-full">
          {loading ? <div className="loader" /> : null}
          <svg
            ref={ref}
            width="100%"
            height="305"
            style={{ opacity: `${loading ? `0` : `1`}` }}
            className="transition duration-300"
          />
        </div>
      ) : (
        `No production data available`
      )}
    </>
  )
}
export default ProductionMap

const callout = (g, value) => {
  if (!value) return g.style("display", "none")

  g.style("display", null)
    .style("pointer-events", "none")
    .style("font", "14px sans-serif")

  const path = g
    .selectAll("path")
    .data([null])
    .join("path")
    .attr("fill", "white")
    .attr("stroke", "lightgrey")

  const text = g
    .selectAll("text")
    .data([null])
    .join("text")
    .call(text =>
      text
        .selectAll("tspan")
        .data((value + "").split(/\n/))
        .join("tspan")
        .attr("x", 0)
        .attr("y", (d, i) => `${i * 1.1}em`)
        .style("font-weight", (_, i) => (i ? null : "bold"))
        .text(d => d)
    )

  const { x, y, width: w, height: h } = text.node().getBBox()

  text.attr("transform", `translate(${-w / 2},${15 - y})`)
  path.attr(
    "d",
    `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`
  )
}
